import React from "react";
import { FooterDiv } from "./Footer.styled";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterDiv>
      <p className="footer">
        &copy; {currentYear} thestudentplus.com All Rights Reserved.
      </p>
    </FooterDiv>
  );
};

export default Footer;

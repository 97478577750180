import styled from "styled-components";

export const FAQ = styled.div`
  &.tablet {
    & > h3 {
      font-size: 36px;
      height: 175px;
    }
    & > div {
      & > .faqs-container {
        & > h3 {
          font-size: 36px;
          margin: 20px 0;
        }
      }
    }
  }
  &.mobile {
    & > h3 {
      font-size: 30px;
      height: 150px;
    }
    & > div {
      & > .faqs-container {
        & > h3 {
          font-size: 30px;
          margin: 20px 0;
        }
      }
    }
  }
  & > h3 {
    height: 250px;
    background: var(--brand-color);
    color: var(--logo-color1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--top-header-font-size);
    margin: 0;
  }
  & > div {
    padding: 24px;
    & p {
      font-size: var(--para-font-size4);
      margin: 0;
      line-height: 1.5;
    }
    & > .faqs-container {
      & > h3 {
        font-size: var(--header-font-size);
        color: var(--brand-color);
      }
    }
  }
`;

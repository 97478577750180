export const loadSessionState = (key: string, defaultValue = null) => {
  try {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  } catch (error) {
    console.error("Failed to load session state:", error);
    return defaultValue;
  }
};

export const saveSessionState = (key: string, value: any) => {
  try {
    // Stringify the value before saving it
    const stringifiedValue = JSON.stringify(value);
    sessionStorage.setItem(key, stringifiedValue);
  } catch (error) {
    console.error("Failed to save session state:", error);
  }
};

export const saveState = (key: string, value: any) => {
  try {
    // Stringify the value before saving it
    const stringifiedValue = JSON.stringify(value);
    localStorage.setItem(key, stringifiedValue);
  } catch (error) {
    console.error("Failed to save state to localStorage:", error);
  }
};

export const loadState = (key: string, defaultValue = null) => {
  try {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  } catch (error) {
    console.error("Failed to load state from localStorage:", error);
    return defaultValue;
  }
};

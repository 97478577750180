import {
  ProgramsContainer,
  ProgramCard,
  ProgramType,
  ProgramDesc,
  ProgramButton,
} from "./Programs.styled";
import { contents } from "../LandingPage/data";
import { Outlet, useNavigate } from "react-router-dom";
import useScreenSize from "../../Hooks/useScreenSize";

const Programs = () => {
  const navigate = useNavigate();
  const screenType = useScreenSize();

  return (
    <>
      <ProgramsContainer className={`programs-container ${screenType}`}>
        <h2>Our Programs</h2>
        <div className="our-programs">
          <p>Explore the wide range of programs we offer at our centre.</p>
          <div className="programs-grid">
            {contents.map((content, index) => (
              <ProgramCard
                key={index}
                onClick={() => navigate(content.explore)}
              >
                <ProgramType
                  dangerouslySetInnerHTML={{ __html: content.type }}
                />
                <h3
                  dangerouslySetInnerHTML={{ __html: content.preparation }}
                ></h3>
                <ProgramDesc>{content.desc}</ProgramDesc>
                <ProgramButton>Explore More</ProgramButton>
              </ProgramCard>
            ))}
          </div>
        </div>
      </ProgramsContainer>
      <Outlet />
    </>
  );
};

export default Programs;

import React from "react";
import { Navigate } from "react-router-dom";
interface Props {
  children: JSX.Element;
}
const ProtectedRoute = ({ children }: Props) => {
  let user = true;
  let userAvailable = false;
  if (user) {
    if (userAvailable) {
      return <Navigate to="/home" replace />;
    } else {
      return children;
    }
  }
  return <Navigate to="/login" replace />;
};

export default ProtectedRoute;

import styled from "styled-components";

export const LayoutDiv = styled.div`
  &:not(.burgerClicked) {
    overflow: hidden;
  }
  & > .layout-child {
    position: relative;
    overflow: auto;
    height: calc(100vh - 73px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.mobile {
      height: calc(100vh - 127px);
      position: relative;
      top: 54.4px;
    }
    &.burgerClicked {
      display: none;
    }
    & > .bot-icon {
      position: relative;
      & > .icon {
        position: fixed;
        bottom: 36px;
        right: 28px;
        font-size: 24px;
        background-color: white;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: transform 0.3s ease, background-color 0.3s ease;
        &.cross {
          transform: rotate(90deg);
          background-color: lightgreen;
          font-size: 20px;
          width: 20px;
          &::before {
            top: 0px;
            position: relative;
            left: 3px;
          }
        }
      }
      & > .dialog {
        display: none;
        position: fixed;
        bottom: 90px;
        right: 28px;
        width: 20px;
        height: 20px;
        padding: 10px;
        background: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        & i {
          color: #551a8b;
        }
        &.two {
          bottom: 138px;
        }
        &.three {
          bottom: 186px;
        }
        &.four {
          bottom: 234px;
        }
        &.five {
          bottom: 282px;
        }
        &.open {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .dialog-header {
          font-size: 18px;
          margin-bottom: 10px;
        }

        .dialog-content {
          font-size: 16px;
        }

        .dialog-close {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }
      }
    }
  }
`;

import styled from "styled-components";

export const AboutDiv = styled.div`
  &.tablet {
    & > h2 {
      font-size: 36px;
      height: 175px;
    }
    & > .about-us {
      & h3 {
        font-size: 36px !important;
        margin: 16px 0 !important;
      }
    }
  }
  &.mobile {
    & > h2 {
      font-size: 30px;
      height: 150px;
    }
    & > .about-us {
      padding: 12px;
      & h3 {
        font-size: 30px !important;
        margin: 14px 0 !important;
      }
      & > .admission-tuition {
        height: unset;
        grid-template-columns: 1fr;
        & > div:first-child {
          height: 402px;
          & > img {
            aspect-ratio: 3/2;
          }
        }
        & > div:last-child {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: var(--logo-color1);
          & > h3 {
            font-size: var(--header-font-size);
            color: var(--brand-color);
            letter-spacing: 1px;
            margin: 10px;
          }
          padding: 20px;

          & > p {
            padding: 0 20px;
          }
        }
      }
      & > .director {
        grid-template-columns: 1fr;
        & > img {
          height: 500px;
          object-fit: cover;
        }
      }
      & > .social-media {
        grid-template-columns: auto auto;
        width: 100%;
        margin-top: 10px;
        gap: 14px;
        & a {
          font-size: 1.3rem;
        }
      }
    }
  }
  & > h2 {
    font-size: var(--top-header-font-size);
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--brand-color);
    color: var(--logo-color1);
    margin: 0;
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 36px;
    & > h3 {
      font-size: var(--header-font-size);
      color: var(--brand-color);
    }
    & p {
      font-size: var(--para-font-size3);
      line-height: 1.5;
      margin: 0;
      & > a > .fab {
        margin-right: 4px;
      }
    }

    .instagram-icon {
      color: #e1306c; /* Instagram color */
    }
    .youtube-icon {
      color: #ff0000; /* YouTube color */
      position: relative;
      right: 2px;
    }

    .facebook-icon {
      color: #1877f2; /* Facebook color */
      position: relative;
      right: 1px;
    }

    .whatsapp-icon {
      color: #25d366; /* WhatsApp color */
    }
    & > .director {
      color: var(--brand-color);
      display: grid;
      grid-template-columns: 50% 50%;
      width: 100vw;
      margin: 20px 0;
      & > img {
        aspect-ratio: 1;
        width: 100%;
        height: 100%;
      }
      & > div {
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: var(--logo-color1);
        & > div {
          text-align: center;
          & > p {
            margin: 10px 0;
            line-height: 1;
            &:nth-child(1) {
              text-align: center;
            }
          }
          & > div {
            margin-top: 20px;
            & > p {
              line-height: 1.5;

              & > strong {
                margin-right: 5px;
              }
            }
          }
        }
        & > h3 {
          font-size: var(--header-font-size);
          color: var(--brand-color);
          text-align: center;
          margin: 0;
        }
      }
    }
    & > .social-media {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-size: var(--para-font-size3);
      gap: 20px;
    }
    & > .admission-tuition {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 600px;
      width: 100vw;
      margin: 20px 0;
      & > div {
        &:first-child {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: var(--logo-color1);
          & > h3 {
            font-size: var(--header-font-size);
            color: var(--brand-color);
            letter-spacing: 1px;
          }
        }
        & > p {
          padding: 0 80px;
          color: var(--brand-color);
          font-size: var(--para-font-size3);
          line-height: 1.5;
          text-align: center;
        }
        & img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

import styled from "styled-components";

export const ProgramsDiv = styled.div``;

export const ProgramsContainer = styled.div`
  &.tablet {
    h2 {
      font-size: 36px;
      height: 175px;
    }
    & > .our-programs {
      & > p {
        font-size: 36px;
      }
    }
  }
  &.mobile {
    h2 {
      font-size: 30px;
      height: 150px;
    }
    & > .our-programs {
      & > p {
        font-size: 30px;
      }
      & > .programs-grid {
        grid-template-columns: 1fr;
      }
    }
  }
  text-align: center;
  background: #f9f9f9;
  h2 {
    font-size: 72px;
    margin-bottom: 20px;
    height: 250px;
    width: 100vw;
    background: var(--brand-color);
    color: var(--logo-color1);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > div {
    padding: 40px;

    & > p {
      font-size: var(--header-font-size);
      margin-bottom: 40px;
      color: var(--brand-color);
      margin: 0;
      margin-bottom: 20px;
    }

    .programs-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }
`;

export const ProgramCard = styled.a`
  cursor: pointer;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s;

  &:hover {
    box-shadow: 0 0 20px 11px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.5s ease-in-out;
  }
`;

export const ProgramType = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;

  /* Styling for the line break tags */
  br {
    display: none;
  }
`;

export const ProgramDesc = styled.p`
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666;
`;

export const ProgramButton = styled.div`
  display: inline-block;
  background: var(--brand-color);
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
  transition: background 0.3s;

  &:hover {
    background: var(--logo-color2);
  }
  &:active {
    background: var(--logo-color1);
  }
`;

import styled from "styled-components";

export const ContactContainer = styled.div`
  &.tablet {
    & > h2 {
      font-size: 36px;
      height: 175px;
    }
  }
  &.mobile {
    & .social-links {
      justify-content: unset;
      & a {
        font-size: 1.1rem;
      }
    }
    & > h2 {
      font-size: 30px;
      height: 150px;
    }
  }
  & > h2 {
    font-size: var(--top-header-font-size);
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--brand-color);
    color: var(--logo-color1);
    margin: 0;
  }
  & > p {
    font-size: var(--para-font-size3);
    line-height: 1.5;
    text-align: center;
    padding: 0 24px;
  }
  & > div {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const Label = styled.label`
  font-weight: bold;
  margin-bottom: 8px;
`;

export const Input = styled.input`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
`;

export const TextArea = styled.textarea`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
  height: 100px;
`;

export const SubmitButton = styled.button`
  padding: 10px 20px;
  background: var(--brand-color);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: var(--logo-color2);
  }
  &:active {
    background: var(--logo-color1);
  }
`;

export const ContactInfo = styled.div`
  margin-top: 40px;
  text-align: left;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1rem;
    margin-bottom: 10px;
    line-height: 2;
    & > strong {
      margin-right: 5px;
    }
  }
`;

export const SocialLinks = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

  a {
    text-align: center;
    font-size: 1.5rem;
    color: inherit;
    transition: color 0.3s;

    &:hover {
      color: #007bff;
    }

    &.instagram-icon:hover {
      color: #e1306c;
    }

    &.youtube-icon:hover {
      color: #ff0000;
    }

    &.facebook-icon:hover {
      color: #1877f2;
    }

    &.whatsapp-icon:hover {
      color: #25d366;
    }
  }
`;

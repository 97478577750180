import useScreenSize from "../../Hooks/useScreenSize";
import { FAQ } from "./Faqs.styled";

const Faqs = () => {
  const screenType = useScreenSize();
  const faqs = [
    {
      question: "What is Student Plus and what makes it unique?",
      answer:
        "Student Plus is a distinguished academic coaching institute in Prayagraj with over 20 years of experience. We prioritize quality education and employ highly qualified teachers to ensure students achieve optimal academic success.",
    },
    {
      question: "What are the key features of Student Plus?",
      answer:
        "Our institute boasts a 20+ year legacy in academic coaching, offers regular communication channels for parents and students, provides personalized mentorship and counseling, and delivers both online and offline classes. Additionally, our classrooms are equipped with smart technology and are fully air-conditioned to enhance the learning environment.",
    },
    {
      question: "How can I enroll my child at Student Plus?",
      answer:
        "To enroll your child, simply visit our website and fill out the enrollment form. You can also visit our campus during office hours for in-person assistance.",
    },
    {
      question: "What modes of classes does Student Plus offer?",
      answer:
        "We offer both online and offline classes to cater to the needs and preferences of our students. Our online classes provide flexibility and convenience, while our offline classes offer a traditional classroom experience with modern amenities.",
    },
    {
      question: "How does Student Plus support students' academic progress?",
      answer:
        "At Student Plus, we provide personalized mentorship and counseling to guide students throughout their academic journey. Our teachers are dedicated to addressing individual learning needs and fostering a supportive learning environment.",
    },
    {
      question: "Can parents monitor their child's progress at Student Plus?",
      answer:
        "Yes, we encourage regular communication with parents through parent-teacher meetings, progress reports, and dedicated communication channels. Parents are actively involved in monitoring their child’s academic growth and development.",
    },
    {
      question:
        "Will the tutorial school provide study material and notebooks?",
      answer:
        "Some tutorials provide study material and some do not. However, there have been instances wherein certain material is distributed as it was deemed essential for proper illustration. You can check with Student Plus - NIOS Student Support Centre in Rajapur to get more information on the same.",
    },
    {
      question:
        "How do I locate Student Plus - NIOS Student Support Centre in Rajapur?",
      answer:
        "These classes are so easy to find because it is just Near Rajapur, Hanuman Mandir Chauraha.",
    },
    {
      question: "How many students does one class accommodate?",
      answer:
        "Student Plus - NIOS Student Support Centre in Allahabad does not believe in overcrowding classes because the children do not get individual attention then. So, they try to fit lesser numbers in each batch. But, batches are divided depending on the total number of students considering the demand for a specific course and the number of batches and instructors available. So, this will change from time to time. ",
    },
    {
      question:
        "What are the class timings at Student Plus - NIOS Student Support Centre?",
      answer:
        "Tutorial classes will be on during Monday:- 8:00 am - 8:00 pm, Tuesday:- 8:00 am - 8:00 pm, Wednesday:- 8:00 am - 8:00 pm, thu:- 8:00 am - 8:00 pm, Friday:- 8:00 am - 8:00 pm, Saturday:- 8:00 am - 8:00 pm, Sunday:- Closed. ",
    },
    {
      question:
        "Can Student Plus - NIOS Student Support Centre arrange for one-on-one classes for my child?",
      answer:
        "Most of the tutorials do not offer one-on-one classes. If you feel your child requires special individual attention with certain subjects, you may approach the authorities at the centre.",
    },
  ];
  return (
    <FAQ className={`faqs ${screenType}`}>
      <h3>FAQs</h3>
      <div>
        <p>
          Welcome to the Student Plus FAQs section! Here, we have compiled
          answers to frequently asked questions to provide you with
          comprehensive information about our institute, programs, and services.
          Whether you're a prospective student, parent, or guardian, explore
          below to find answers to common queries about enrollment, classes,
          academic support, facilities, and more. If you have additional
          questions or need further assistance, feel free to contact us
          directly. We're here to help you navigate your educational journey
          with Student Plus.
        </p>
        {faqs.map((faq) => (
          <div className="faqs-container" key={faq.question}>
            <h3>{faq.question}</h3>
            <p>{faq.answer}</p>
          </div>
        ))}
      </div>
    </FAQ>
  );
};

export default Faqs;

import { useState } from "react";
import {
  ContactContainer,
  ContactInfo,
  Form,
  Input,
  InputContainer,
  Label,
  SocialLinks,
  SubmitButton,
  TextArea,
} from "./Contact.styled";
import emailjs from "emailjs-com";
import useScreenSize from "../../Hooks/useScreenSize";

const ContactPage = () => {
  const screenType = useScreenSize();
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    phone: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Handle form submission logic here
    setLoader(true);
    const templateParams = {
      from_name: formData.name,
      subject: formData.subject,
      message: formData.message,
      email: formData.email,
      phone: formData.phone,
      to_name: "The Student Plus",
    };

    emailjs
      .send(
        "service_6tewb1g",
        "template_52ar38b",
        templateParams,
        "k3sNbceyd8PaZRB0z",
      )
      .then(
        (response) => {
          setLoader(false);
          alert("Email sent successfully!");
        },
        (err) => {
          setLoader(false);
          alert("Failed to send email.");
          console.log(err);
        },
      );
  };

  return (
    <ContactContainer className={`contact-us ${screenType}`}>
      <h2>Contact Us</h2>
      <p>
        We'd love to hear from you! Please fill out the form below to get in
        touch.
      </p>
      <div className="form-contact-details">
        <Form onSubmit={handleSubmit}>
          <InputContainer>
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </InputContainer>
          <InputContainer>
            <Label htmlFor="phone">Phone</Label>
            <Input
              type="phone"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </InputContainer>
          <InputContainer>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </InputContainer>

          <InputContainer>
            <Label htmlFor="subject">Subject</Label>
            <Input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </InputContainer>

          <InputContainer>
            <Label htmlFor="message">Message</Label>
            <TextArea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </InputContainer>

          <SubmitButton type="submit">
            {loader ? "Sending..." : "Send Message"}
          </SubmitButton>
        </Form>

        <ContactInfo className="contact-info">
          <h2>Contact Information</h2>
          <p>
            <strong>Head Office:</strong>
            {"    "} 23/29 Clive Road, Hanuman Mandir Chauraha, Rajapur,
            Prayagraj.
          </p>
          <p>
            <strong>Mobile:</strong>
            {"    "}+919335057978
          </p>
          <p>
            <strong>Email:</strong>
            {"    "}
            <a href="mailto:info@thestudentplus.com">info@thestudentplus.com</a>
          </p>

          <SocialLinks className="social-links">
            <a
              href="https://wa.me/9335057978"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-whatsapp whatsapp-icon"></i>
              {"    "}+919335057978
            </a>
            <br />
            <a
              href="https://instagram.com/thestudentplus_official?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram instagram-icon"></i>
              {"    "}thestudentplus_official
            </a>
            <br />
            <a
              href="https://www.youtube.com/@StudentPlus_Official"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube youtube-icon"></i>
              {"    "}StudentPlus_Official
            </a>
            <br />
            <a
              href="https://www.facebook.com/studentplusallahabad/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook facebook-icon"></i>
              {"    "}studentplusallahabad
            </a>
          </SocialLinks>
        </ContactInfo>
      </div>
    </ContactContainer>
  );
};

export default ContactPage;

import "./App.css";
import LandingPage from "./pages/LandingPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./pages/About";
import ProtectedRoute from "./Navigation/ProtectedRoute";
import Layout from "./Navigation/Layout";
import Faqs from "./pages/Faqs";
import Programs from "./pages/Programs";
import ContactPage from "./pages/Contact";
import Nios from "./pages/Nios";
import RishikulamSchool from "./pages/RishikulamSchool";
import BoardPreparation from "./pages/CompetitivePreparation";
import AcademicPreparation from "./pages/AcademicPreparation";
import CacheBuster from "react-cache-buster";
import packageFile from "../package.json";
function App() {
  const clientData = {
    class: "radha-001",
    mobNum: "+919335057978",
    whatsApp: "",
    facebook: "",
    twitter: "",
  };
  const { version } = packageFile;
  const isProduction = process.env.NODE_ENV === "production";

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <div className="student-plus-app">
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Layout data={clientData} />
                </ProtectedRoute>
              }
            >
              <Route path="/home" element={<LandingPage data={clientData} />} />
              <Route path="/about" element={<About />} />
              <Route path="/faq" element={<Faqs />} />
              <Route path="/programs" element={<Programs />} />

              <Route
                path="/programs/academic-preparation"
                element={<AcademicPreparation />}
              />
              <Route
                path="/programs/board-preparation"
                element={<BoardPreparation />}
              />
              <Route
                path="/programs/rishikulam-school"
                element={<RishikulamSchool />}
              />
              <Route path="/programs/nios" element={<Nios />} />
              <Route path="/contact" element={<ContactPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </CacheBuster>
  );
}

export default App;

import styled from "styled-components";

export const Hamburger = styled.div`
  display: inline-block;
  cursor: pointer;
  position: relative;
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease;

  & div {
    background-color: #333;
    height: 4px;
    width: 100%;
    border-radius: 4px;
    position: absolute;
    transition: all 0.3s ease;
  }

  & div:nth-child(1) {
    top: 8px;
  }

  & div:nth-child(2) {
    top: 16px;
  }

  & div:nth-child(3) {
    top: 24px;
  }

  &.open div:nth-child(1) {
    transform: rotate(45deg);
    top: 14px;
  }

  &.open div:nth-child(2) {
    opacity: 0;
  }

  &.open div:nth-child(3) {
    transform: rotate(-45deg);
    top: 14px;
  }
`;

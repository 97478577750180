import { NIOSDIV } from "./Nios.styled";
import { useNavigate } from "react-router-dom";
import useScreenSize from "../../Hooks/useScreenSize";
import Nios1 from "../../assets/images/nios1.jpg";
import Nios2 from "../../assets/images/nios2.jpg";
import Nios3 from "../../assets/images/nios3.jpg";
import Toppers from "../../assets/images/toppers.png";

const Nios = () => {
  const navigate = useNavigate();
  const screenType = useScreenSize();

  return (
    <NIOSDIV className={`nios-container ${screenType}`}>
      <h3>NIOS Preparation</h3>
      <div className="nios-preparation">
        <button
          className="fancy-back-button"
          onClick={() => navigate("../programs")}
        >
          <i className="fas fa-arrow-left"></i> Back
        </button>
        <h3>What is NIOS?</h3>
        <p>
          {" "}
          NIOS (National Institute of Open Schooling) is an Indian
          government-run organization that offers distance and open learning
          education to students. It was established in 1989 and is part of the
          Ministry of Education.
          <br />
          <br />
          <strong>NIOS provides:</strong>
          <br />
          Secondary and Senior Secondary courses: Equivalent to grades 10 and
          12, respectively.
          <br />
          <br />
          <strong>NIOS features:</strong>
          <ol>
            <li>
              <strong>⏳ Flexibility:</strong> Learn at your own pace, anytime,
              and from anywhere.
            </li>

            <li>
              <strong>🌍 Accessibility:</strong> Reach remote and disadvantaged
              areas.
            </li>

            <li>
              <strong>🎓 Equivalency:</strong> NIOS certifications are
              recognized by various boards, universities, and employers.
            </li>
          </ol>
        </p>
        <h3>How NIOS helps JEE/NEET Aspirants?</h3>
        <p>
          NIOS (National Institute of Open Schooling) can be a valuable option
          for JEE and NEET aspirants in several ways:
          <br />
          <ol>
            <li>
              <strong>⏳ Additional Time to Prepare:</strong> NIOS allows
              students to take exams at their own pace, giving them extra time
              to focus on JEE/NEET preparation.
            </li>

            <li>
              <strong>📚 Flexibility in Curriculum:</strong> NIOS offers a
              flexible curriculum, enabling students to concentrate on subjects
              relevant to JEE/NEET, like Physics, Chemistry, and Biology.
            </li>

            <li>
              <strong>🔍 Personalized Learning:</strong> NIOS's self-paced
              learning approach helps students focus on weak areas and
              strengthen their fundamentals.
            </li>

            <li>
              <strong>🧘 Reduced Academic Pressure:</strong> NIOS can alleviate
              some pressure by allowing students to complete their secondary
              education while preparing for competitive exams.
            </li>

            <li>
              <strong>🎓 Equivalent Certification:</strong> NIOS certifications
              are recognized by various boards, universities, and entrance
              exams, including JEE and NEET.
            </li>

            <li>
              <strong>📋 Eligibility for Entrance Exams:</strong> NIOS students
              are eligible to appear for JEE and NEET, as well as other
              competitive exams.
            </li>

            <li>
              <strong>🔄 Support for Repeaters:</strong> NIOS provides an
              opportunity for students who have failed or want to improve their
              scores in JEE/NEET to continue their studies while preparing.
            </li>

            <li>
              <strong>📘 Access to Quality Study Materials:</strong> NIOS
              provides study materials, which can supplement JEE/NEET
              preparation.
            </li>
          </ol>
        </p>
        <h3>
          Understanding NIOS Top 20 Percentile and Its Impact on JEE/NEET
          Eligibility
        </h3>
        <p>
          📊{" "}
          <strong>
            The Top 20 Percentile for NIOS (National Institute of Open
            Schooling):
          </strong>{" "}
          Refers to the minimum percentage of marks required to be considered
          among the top 20% of NIOS students.
        </p>

        <p>
          📈 <strong>Percentile Calculation:</strong> As per the NIOS policy,
          the top 20 percentile is calculated based on the total number of
          students who appeared for the exam, not the total number of students
          enrolled. This means that only students who have actually taken the
          exam are considered for the percentile calculation.
        </p>

        <p>
          📅 <strong>Typical Percentile Marks:</strong> For NIOS, the top 20
          percentile is typically around 60-70% marks in aggregate (all
          subjects). However, this can vary depending on the number of students
          and their performance in a particular exam cycle.
        </p>

        <p>
          📉 <strong>Estimated Top 20 Percentile Marks:</strong>
          <ul>
            <li>
              <strong>Secondary (Class 10):</strong> 55-65%
            </li>
            <li>
              <strong>Senior Secondary (Class 12):</strong> 60-70%
            </li>
          </ul>
          Please note that these are approximate values and may vary. The exact
          top 20 percentile marks are announced by NIOS after each exam cycle.
        </p>

        <p>
          🎓 <strong>JEE and NEET Eligibility:</strong> For JEE and NEET
          eligibility, students need to secure a minimum of 45% marks (40% for
          reserved categories) in their Class 12 exams, which includes NIOS.
          However, meeting the top 20 percentile criteria can provide an added
          advantage.
        </p>
      </div>
      <div className="our-results">
        <h3>Students' Achievements</h3>
        <img src={Toppers} alt="" />
        <img src={Nios3} alt="" />
        <img src={Nios1} alt="" />
        <img src={Nios2} alt="" />
      </div>
    </NIOSDIV>
  );
};

export default Nios;

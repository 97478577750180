import { RishiKulam } from "./RishikulamSchool.styled";
import { useNavigate } from "react-router-dom";
import useScreenSize from "../../Hooks/useScreenSize";
import Nios1 from "../../assets/images/nios1.jpg";
import Nios2 from "../../assets/images/nios2.jpg";
import Nios3 from "../../assets/images/nios3.jpg";
import Toppers from "../../assets/images/toppers.png";
const RishikulamSchool = () => {
  const navigate = useNavigate();
  const screenType = useScreenSize();
  return (
    <RishiKulam className={`rishikulam-container ${screenType}`}>
      <h3>Rishikulam: A Futuristic School</h3>
      <div className="rishikulam-school">
        <button
          className="fancy-back-button"
          onClick={() => navigate("../programs")}
        >
          <i className="fas fa-arrow-left"></i> Back
        </button>
        <h3>
          A futuristic school is an educational institution that combines
          multiple levels of education, such as secondary, and senior secondary,
          under one roof. This approach offers several benefits, including:
        </h3>
        <ol>
          <li>
            <strong>🔗 Seamless Transition:</strong> Students can progress
            smoothly from one level to the next without adjusting to a new
            environment.
          </li>

          <li>
            <strong>🔄 Consistency and Continuity:</strong> Teaching methods,
            values, and policies remain consistent throughout the student's
            educational journey.
          </li>

          <li>
            <strong>🌱 Holistic Development:</strong> futuristic schools focus
            on overall development, including academic, extracurricular, and
            personal growth.
          </li>

          <li>
            <strong>📚 Streamlined Curriculum:</strong> The curriculum is
            designed to flow logically from one level to the next, reducing
            repetition and gaps.
          </li>

          <li>
            <strong>💼 Efficient Resource Utilization:</strong> Shared
            resources, facilities, and faculty optimize efficiency and reduce
            costs.
          </li>

          <li>
            <strong>🤝 Stronger Community Bonds:</strong> Students, teachers,
            and parents form a close-knit community, fostering relationships and
            a sense of belonging.
          </li>

          <li>
            <strong>👩‍🏫 Personalized Attention:</strong> With a smaller student
            body, teachers can provide more individualized attention and
            support.
          </li>

          <li>
            <strong>📊 Better Student Tracking:</strong> Teachers can monitor
            student progress and identify areas for improvement more easily.
          </li>

          <li>
            <strong>🎨 Enhanced Extracurricular Opportunities:</strong>{" "}
            futuristic schools often offer a broader range of activities and
            sports programs.
          </li>

          <li>
            <strong>🏠 Convenience for Parents:</strong> A single institution
            for all educational needs simplifies logistics and communication.
          </li>
        </ol>
      </div>
      <div className="our-results">
        <h3>Students' Achievements</h3>
        <img src={Toppers} alt="" />
        <img src={Nios3} alt="" />
        <img src={Nios1} alt="" />
        <img src={Nios2} alt="" />
      </div>
    </RishiKulam>
  );
};

export default RishikulamSchool;

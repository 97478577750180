import { useNavigate } from "react-router-dom";
import { CompPrep } from "./CompetitivePreparation.styled";
import useScreenSize from "../../Hooks/useScreenSize";
import Nios1 from "../../assets/images/nios1.jpg";
import Nios2 from "../../assets/images/nios2.jpg";
import Nios3 from "../../assets/images/nios3.jpg";
import Toppers from "../../assets/images/toppers.png";
const CompetitivePreparation = () => {
  const navigate = useNavigate();
  const screenType = useScreenSize();

  return (
    <CompPrep className={`competitive-container ${screenType}`}>
      <h3>Competitive Preparation</h3>
      <div className="competitive-preparation">
        <button
          className="fancy-back-button"
          onClick={() => navigate("../programs")}
        >
          <i className="fas fa-arrow-left"></i> Back
        </button>
        <h3>
          {" "}
          Here are some effective teaching strategies for success in competitive
          exams like JEE and NEET:
        </h3>
        <ol>
          <li>
            <strong>📚 Conceptual Clarity:</strong> Focus on building strong
            fundamentals to ensure students understand concepts thoroughly.
          </li>
          <li>
            <strong>🗂️ Structured Lessons:</strong> Organize lessons in a
            logical sequence, covering all topics and subtopics systematically.
          </li>
          <li>
            <strong>🧠 Practice-Oriented Approach:</strong> Encourage regular
            practice by providing a variety of questions and problems to solve.
          </li>
          <li>
            <strong>🎯 Personalized Attention:</strong> Identify and address
            individual strengths, weaknesses, and learning styles.
          </li>
          <li>
            <strong>💻 Technology Integration:</strong> Utilize digital
            resources, simulations, and online tools to enhance learning
            experiences.
          </li>
          <li>
            <strong>📝 Test Series and Mock Tests:</strong> Conduct regular mock
            tests to help students assess their performance and build exam
            endurance.
          </li>
          <li>
            <strong>❓ Doubt Clearing Sessions:</strong> Allocate time for
            students to clarify doubts and reinforce understanding.
          </li>
          <li>
            <strong>🔄 Revision and Consolidation:</strong> Ensure regular
            revision by summarizing key concepts and formulas.
          </li>
          <li>
            <strong>⏳ Exam Strategy and Time Management:</strong> Teach
            effective exam strategies, time management, and question selection
            techniques.
          </li>
          <li>
            <strong>🌟 Motivation and Support:</strong> Foster a positive
            learning environment to keep students motivated and focused.
          </li>
          <li>
            <strong>🔍 Micro-Teaching:</strong> Break down complex topics into
            smaller, manageable chunks.
          </li>
          <li>
            <strong>🔄 Active Learning:</strong> Encourage active engagement
            through discussions, group work, and problem-solving.
          </li>
          <li>
            <strong>📊 Formative Assessments:</strong> Regularly assess student
            progress to identify areas for improvement.
          </li>
          <li>
            <strong>🎤 Guest Lectures and Workshops:</strong> Organize sessions
            with experts to provide additional insights and perspectives.
          </li>
          <li>
            <strong>🏠 Parent-Teacher Interaction:</strong> Keep parents
            informed about student progress and involve them in the learning
            process.
          </li>
        </ol>
      </div>
      <div className="our-results">
        <h3>Students' Achievements</h3>
        <img src={Toppers} alt="" />
        <img src={Nios3} alt="" />
        <img src={Nios1} alt="" />
        <img src={Nios2} alt="" />
      </div>
    </CompPrep>
  );
};

export default CompetitivePreparation;

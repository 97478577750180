import { useNavigate } from "react-router-dom";
import { AcademicPrep } from "./AcademicPreparation.styled";
import useScreenSize from "../../Hooks/useScreenSize";
import Nios1 from "../../assets/images/nios1.jpg";
import Nios2 from "../../assets/images/nios2.jpg";
import Nios3 from "../../assets/images/nios3.jpg";
import Toppers from "../../assets/images/toppers.png";
const AcademicPreparation = () => {
  const navigate = useNavigate();
  const screenType = useScreenSize();

  return (
    <AcademicPrep className={`academic-container ${screenType}`}>
      <h3>Academic Preparation</h3>
      <div className="academic-preparation">
        <button
          className="fancy-back-button"
          onClick={() => navigate("../programs")}
        >
          <i className="fas fa-arrow-left"></i> Back
        </button>
        <h3>
          Teaching weak students requires a supportive and tailored approach.
          Here are some strategies we use to help:
        </h3>
        <ol>
          <li>
            <strong>🔍 Identify Learning Gaps:</strong> Understand where they
            struggle and focus on filling those gaps.
          </li>

          <li>
            <strong>🔢 Break Topics into Smaller Chunks:</strong> Simplify
            complex concepts into manageable pieces.
          </li>

          <li>
            <strong>🖼️ Use Visual Aids and Multimedia:</strong> Engage them with
            diagrams, videos, and interactive resources.
          </li>

          <li>
            <strong>📚 Provide Extra Support and Practice:</strong> Offer
            additional help, worksheets, and practice exercises.
          </li>

          <li>
            <strong>💬 Encourage Active Learning:</strong> Engage them in
            discussions, group work, and hands-on activities.
          </li>

          <li>
            <strong>🏆 Build Confidence:</strong> Praise effort, progress, and
            small successes.
          </li>

          <li>
            <strong>🔄 Differentiate Instruction:</strong> Adapt teaching
            methods to meet individual learning styles.
          </li>

          <li>
            <strong>🌟 Make it Relevant and Relatable:</strong> Connect lessons
            to real-life scenarios or interests.
          </li>

          <li>
            <strong>🎉 Celebrate Small Victories:</strong> Recognize and
            celebrate progress, no matter how small.
          </li>

          <li>
            <strong>💖 Be Patient and Empathetic:</strong> Create a safe,
            supportive learning environment.
          </li>

          <li>
            <strong>👨‍👩‍👧 Involve Parents/Guardians:</strong> Keep them informed
            and involved in their child's progress.
          </li>

          <li>
            <strong>📝 Provide Feedback, Not Criticism:</strong> Offer
            constructive feedback that guides improvement.
          </li>

          <li>
            <strong>🪞 Encourage Self-Reflection:</strong> Help them set goals
            and reflect on their own learning.
          </li>

          <li>
            <strong>💻 Use Technology Strategically:</strong> Leverage digital
            tools and resources to supplement learning.
          </li>

          <li>
            <strong>🌱 Foster a Growth Mindset:</strong> Encourage them to view
            challenges as opportunities for growth.
          </li>
        </ol>
      </div>
      <div className="our-results">
        <h3>Students' Achievements</h3>
        <img src={Toppers} alt="" />
        <img src={Nios3} alt="" />
        <img src={Nios1} alt="" />
        <img src={Nios2} alt="" />
      </div>
    </AcademicPrep>
  );
};

export default AcademicPreparation;

import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../../pages/Header";
import Footer from "../../pages/Footer";
import { LayoutDiv } from "./Layout.styled";
import useScreenSize from "../../Hooks/useScreenSize";

type Props = {
  data: any;
};
const Layout = ({ data }: Props) => {
  const navigate = useNavigate();
  const [openDialogue, setOpenDialogue] = useState(false);
  const screenType = useScreenSize();
  const location = useLocation().pathname;
  const [burgerClicked, setBurgerClicked] = useState(false);
  const layoutChild = useRef<any>(null);
  const handleClick = (data: any) => {
    setBurgerClicked(data);
  };
  useEffect(() => {
    location !== "/" ? navigate(location) : navigate("home");
    layoutChild.current && layoutChild.current.scrollTo(0, 0);
  }, [location]);
  return (
    <LayoutDiv className={`${burgerClicked ? "burgerClicked" : ""}`}>
      <Header data={data} onHamburgerClick={handleClick} />
      <div
        ref={layoutChild}
        className={`layout-child ${screenType} ${
          burgerClicked ? "burgerClicked" : ""
        }`}
      >
        <Outlet />
        <Footer />
        <div className="bot-icon">
          <i
            onClick={() => setOpenDialogue(!openDialogue)}
            className={`fas icon ${
              openDialogue ? "cross fa-times" : "fa-ellipsis-h "
            }`}
          ></i>
          <div className={`dialog one ${openDialogue ? "open" : ""}`}>
            <a
              href={`tel:${data.mobNum}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-phone"></i>
            </a>
          </div>
          <div className={`dialog two ${openDialogue ? "open" : ""}`}>
            <a
              href="https://wa.me/9335057978"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-whatsapp whatsapp-icon"></i>
            </a>
          </div>
          <div className={`dialog three ${openDialogue ? "open" : ""}`}>
            <a
              href="https://instagram.com/thestudentplus_official?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram instagram-icon"></i>
            </a>
          </div>
          <div className={`dialog four ${openDialogue ? "open" : ""}`}>
            <a
              href="https://www.youtube.com/@StudentPlus_Official"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube youtube-icon"></i>
            </a>
          </div>
          <div className={`dialog five ${openDialogue ? "open" : ""}`}>
            <a
              href="https://www.facebook.com/studentplusallahabad/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook facebook-icon"></i>
            </a>
          </div>
        </div>
      </div>
    </LayoutDiv>
  );
};

export default Layout;

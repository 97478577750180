import { LandingPageContainer } from "./LandingPage.styled";
import WelcomeImage from "../../assets/images/welcome-image.jpg";
import { useNavigate } from "react-router-dom";
import Vision from "../../assets/videos/vision.mp4";
import { contents, studentsAchievements } from "./data";
import { useState } from "react";
import emailjs from "emailjs-com";
import useScreenSize from "../../Hooks/useScreenSize";
import Nios1 from "../../assets/images/nios1.jpg";
import Nios2 from "../../assets/images/nios2.jpg";
import Nios3 from "../../assets/images/nios3.jpg";
import Toppers from "../../assets/images/toppers.png";
type Props = {
  data: any;
};
const LandingPage = ({ data }: Props) => {
  const navigate = useNavigate();
  // const location = useLocation().pathname;
  const screenType = useScreenSize();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    phone: "",
  });
  const [loader, setLoader] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Handle form submission logic here

    const templateParams = {
      from_name: formData.name,
      subject: formData.subject,
      message: formData.message,
      email: formData.email,
      phone: formData.phone,
      to_name: "The Student Plus",
    };
    setLoader(true);
    emailjs
      .send(
        "service_6tewb1g",
        "template_52ar38b",
        templateParams,
        "k3sNbceyd8PaZRB0z",
      )
      .then(
        (response) => {
          setLoader(false);
          alert("Email sent successfully!");
        },
        (err) => {
          setLoader(false);
          alert("Failed to send email.");
          console.log(err);
        },
      );
  };
  return (
    <LandingPageContainer
      data={data}
      className={`radhe-001 landing-container ${screenType}`}
    >
      <div>
        <div className="welcome-image">
          <img src={WelcomeImage} alt="" />
          <div className="welcome-image-content">
            <div className="title">Welcome to The Student Plus</div>
            <div className="description">
              We are dedicated to providing high-quality education to students
              from 6th to 12th grade, catering to all boards across India. We
              also offer coaching for competitive exams like JEE, NEET, and have
              recently established our own school, Rishikulam, for classes 9th
              to 12th.
            </div>
          </div>
        </div>
        <div className="our-programs">
          <h2>Our Programs</h2>
          <div className="content">
            {contents.map((content, i) => (
              <div key={content.type + i}>
                <h3 dangerouslySetInnerHTML={{ __html: content.type }}></h3>
                <h4
                  dangerouslySetInnerHTML={{ __html: content.preparation }}
                ></h4>
                <p>{content.desc}</p>
                <button onClick={() => navigate(content.redirect)}>
                  Read More
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className={`our-vision`}>
          <div className="content">
            <div>
              <h1>Our Vision</h1>
              <p>
                At The Student Plus, we strive to empower students with
                knowledge and skills essential for their academic and
                professional growth. Click to learn more about our vision.
              </p>
              <button onClick={() => navigate("../about")}>Read More</button>
            </div>
          </div>
          <div className="content-video">
            <video width="600" autoPlay loop muted>
              <source src={Vision} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className="address-form">
          <div className="address">
            {screenType === "mobile" ? null : <h1>Get In Touch</h1>}

            <h4>Visit Us -</h4>
            <p>
              23, 29, Clive Rd, near Hanuman Mandir Chauraha, Vivek Vihar
              Colony, Rajapur, Prayagraj, Uttar Pradesh 211001
            </p>
            <h4>Email us -</h4>
            <p>info@thestudentplus.com</p>
            <h4>Contact Us -</h4>
            <p>9335057978</p>
          </div>
          <div className="form-data">
            {screenType === "mobile" ? <h1>Get In Touch</h1> : null}

            <form onSubmit={handleSubmit}>
              <div>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  required
                />
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone"
                  required
                />
              </div>

              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                required
              />

              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Subject"
                required
              />

              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Type your message here..."
                required
              />

              <button type="submit">
                {loader ? "Sending..." : "Send Message"}
              </button>
            </form>
          </div>
        </div>
        <div className="our-results">
          <h3>Students' Achievements</h3>
          <img src={Toppers} alt="" />
          <img src={Nios3} alt="" />
          <img src={Nios1} alt="" />
          <img src={Nios2} alt="" />
        </div>
      </div>
    </LandingPageContainer>
  );
};

export default LandingPage;

import styled from "styled-components";

export const HeaderDiv = styled.div<{ data: any }>`
  &.mobile {
    & .mobile-view {
      font-size: 1.2rem;
      .instagram-icon {
        color: #e1306c; /* Instagram color */
      }
      .youtube-icon {
        color: #ff0000; /* YouTube color */
      }

      .facebook-icon {
        color: #1877f2; /* Facebook color */
      }

      .whatsapp-icon {
        color: #25d366; /* WhatsApp color */
      }
      display: flex;
      justify-content: space-between;
      padding: 16px;
      width: calc(100% - 20px);
      position: absolute;
      top: 72px;
      background: white;
      z-index: 1;
      & > a {
        text-decoration: none;
        color: #3c4449;
      }
      & > ${(props) => `.${props.data.class}-social-media`} {
        display: flex;
        gap: 8px;
      }
    }
    position: relative;
    display: flex;
    height: 73px;
    justify-content: space-between;

    & > ${(props) => `.${props.data.class}-navigator`} {
      text-align: center;
      display: none;
      position: absolute;
      top: 125px;
      width: 100%;
      font-size: 2rem;
      & > div {
        margin: 44px;
      }
      &.burgerClicked {
        display: block;
      }
    }
    & > ${(props) => `.${props.data.class}-brand-name`} {
      & > div.logo-name {
        left: 84px;
        top: 22px;
      }
      & > div > img {
        top: 10px;
        left: 17px;
      }
    }
    & > .hamburger {
      position: relative;
      top: 21px;
      right: 10px;
    }
  }
  &.ldesktop,
  &.mdesktop,
  &.tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 21px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    & > div {
      font-size: 14px;
    }
  }
  & > ${(props) => `.${props.data.class}-brand-name`} {
    line-height: 1.41em;
    font-size: 22px;
    color: #030e14;
    cursor: pointer;
    position: relative;
    display: flex;
    cursor: pointer;

    & > div.logo-name {
      position: relative;
      left: 56px;
      font-size: 24px;
    }
    & > div > img {
      width: 54px;
      position: absolute;
      top: -16px;
      left: -8px;
      z-index: -1;
    }
  }
  & > ${(props) => `.${props.data.class}-navigator`} {
    display: flex;
    align-items: center;
    gap: 20px;
    color: #3c4449;
    & > .active {
      color: blue;
    }
    & > div {
      cursor: pointer;
    }
    & > a {
      text-decoration: none;
      color: #3c4449;
    }
    & > ${(props) => `.${props.data.class}-social-media`} {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: large;
      position: relative;
      bottom: 2px;
      & > a {
        cursor: pointer;
      }
    }
  }
  .instagram-icon {
    color: #e1306c; /* Instagram color */
  }
  .youtube-icon {
    color: #ff0000; /* YouTube color */
  }

  .facebook-icon {
    color: #1877f2; /* Facebook color */
  }

  .whatsapp-icon {
    color: #25d366; /* WhatsApp color */
  }
`;

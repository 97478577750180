export const contents = [
  {
    type: "Academic Preparation",
    preparation: "Board Preparation",
    desc: "Our programs are designed to ensure comprehensive learning and preparation for board exams. Click to explore more.",
    redirect: "/programs",
    explore: "academic-preparation",
  },
  {
    type: "Competitive Preparation",
    preparation: "Competitive Exam Preparation",
    desc: "Prepare effectively for JEE, NEET, and other competitive exams with our expert coaching. Click to explore more",
    redirect: "/programs",
    explore: "board-preparation",
  },
  {
    type: "Rishikulam A Futuristic School",
    preparation: "Classes 9th -12th",
    desc: "Introducing Rishikulam A Futuristic School, our school provides a holistic education for classes 9th to 12th. Click to explore more.",
    redirect: "/programs",
    explore: "rishikulam-school",
  },
  {
    type: "NIOS",
    preparation: "Direct Admission",
    desc: "Golden opportunity for JEE/NEET Apirants & Dropouts to insure their top 20 percentile.",
    redirect: "/programs",
    explore: "nios",
  },
];

export const studentsAchievements = [
  {
    url: "https://images.pexels.com/photos/16332000/pexels-photo-16332000/free-photo-of-student-at-a-university-graduation.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
    desc: "Discover stories of our successful students and their journeys with The Student Plus.",
    studentName: "Rajan Malhotra",
  },
  {
    url: "https://images.pexels.com/photos/1462630/pexels-photo-1462630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    desc: `Read about Rajan's inspiring journey from academic struggles to success with The Student Plus.`,
    studentName: "Aisha Patel",
  },
  {
    url: "https://images.pexels.com/photos/5538007/pexels-photo-5538007.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
    desc: `Explore Aisha's remarkable achievements and her experience at The Student Plus.`,
    studentName: "Shreya Singh",
  },
];

import { useState } from "react";
import { Hamburger } from "./HamburgerIcon.styled";
import { saveSessionState } from "../utils";
type Props = {
  onHamburgerClick: any;
};
const HamburgerIcon = ({ onHamburgerClick }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    onHamburgerClick(!isOpen);
    setIsOpen(!isOpen);
    saveSessionState("burgerClicked", !isOpen);
  };

  return (
    <Hamburger
      id="ss-radha-hamburger-icon"
      className={`hamburger ${isOpen ? "open" : ""}`}
      onClick={toggleMenu}
    >
      <div></div>
      <div></div>
      <div></div>
    </Hamburger>
  );
};

export default HamburgerIcon;

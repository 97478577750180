import { useLocation, useNavigate } from "react-router-dom";
import { HeaderDiv } from "./Header.styled";
import Logo from "../../assets/images/logo1.png";
import useScreenSize from "../../Hooks/useScreenSize";
import HamburgerIcon from "../../Components/HamburgerIcon";
import { loadSessionState } from "../../utils";
type Props = {
  data: any;
  onHamburgerClick: any;
};
const Header = ({ data, onHamburgerClick }: Props) => {
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const screenType = useScreenSize();
  const socialMedia = () => {
    return (
      <div className={`${data.class}-social-media`}>
        <a
          href="https://wa.me/9335057978"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-whatsapp whatsapp-icon"></i>
        </a>
        <br />
        <a
          href="https://instagram.com/thestudentplus_official?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram instagram-icon"></i>
        </a>
        <br />
        <a
          href="https://www.youtube.com/@StudentPlus_Official"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-youtube youtube-icon"></i>
        </a>
        <br />
        <a
          href="https://www.facebook.com/studentplusallahabad/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook facebook-icon"></i>
        </a>
      </div>
    );
  };
  return (
    <>
      <HeaderDiv className={`${screenType}`} data={data}>
        <div
          className={`${data.class}-brand-name`}
          onClick={() => navigate("/home")}
        >
          <div>
            <img src={Logo} alt="" loading="lazy" />
          </div>
          <div className="logo-name">The Student Plus</div>
        </div>
        {screenType === "mobile" ? (
          <HamburgerIcon onHamburgerClick={onHamburgerClick} />
        ) : null}
        {screenType === "mobile" && (
          <div className="mobile-view">
            <a
              href={`tel:${data.mobNum}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>{data.mobNum.slice(3)}</div>
            </a>
            {socialMedia()}
          </div>
        )}
        <div
          className={`${data.class}-navigator ${
            loadSessionState("burgerClicked") ? "burgerClicked" : ""
          }`}
        >
          <div
            className={`${location.indexOf("/home") > -1 ? "active" : ""}`}
            onClick={() => {
              if (loadSessionState("burgerClicked")) {
                let icon = document.getElementById("ss-radha-hamburger-icon");
                icon?.click();
              }
              navigate("/home");
            }}
          >
            Home
          </div>
          <div
            className={`${location.indexOf("/about") > -1 ? "active" : ""}`}
            onClick={() => {
              if (loadSessionState("burgerClicked")) {
                let icon = document.getElementById("ss-radha-hamburger-icon");
                icon?.click();
              }
              navigate("/about");
            }}
          >
            About
          </div>
          <div
            className={`${location.indexOf("/programs") > -1 ? "active" : ""}`}
            onClick={() => {
              if (loadSessionState("burgerClicked")) {
                let icon = document.getElementById("ss-radha-hamburger-icon");
                icon?.click();
              }
              navigate("/programs");
            }}
          >
            Programs
          </div>
          <div
            className={`${location.indexOf("/faq") > -1 ? "active" : ""}`}
            onClick={() => {
              if (loadSessionState("burgerClicked")) {
                let icon = document.getElementById("ss-radha-hamburger-icon");
                icon?.click();
              }
              navigate("/faq");
            }}
          >
            FAQs
          </div>
          <div
            className={`${location.indexOf("/contact") > -1 ? "active" : ""}`}
            onClick={() => {
              if (loadSessionState("burgerClicked")) {
                let icon = document.getElementById("ss-radha-hamburger-icon");
                icon?.click();
              }
              navigate("/contact");
            }}
          >
            Contact
          </div>

          {screenType === "mobile" ? null : (
            <>
              <a
                href={`tel:${data.mobNum}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>{data.mobNum.slice(3)}</div>
              </a>
              {socialMedia()}
            </>
          )}
        </div>
      </HeaderDiv>
    </>
  );
};

export default Header;

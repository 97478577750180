import { AboutDiv } from "./About.styled";
import Vipul from "../../assets/images/vipul.jpeg";
import AboutPic from "../../assets/images/aboutPic.jpg";
import useScreenSize from "../../Hooks/useScreenSize";

const About = () => {
  const screenType = useScreenSize();
  return (
    <AboutDiv className={`about-us-container ${screenType}`}>
      <h2>About Us</h2>
      <div className="about-us">
        <h3>
          Welcome to Student Plus - Shaping Success for 24 Years in Prayagraj!
        </h3>
        <p>
          At Student Plus, we pride ourselves on being a premier academic
          coaching institute with a legacy of excellence spanning over two
          decades. Our mission is to deliver top-tier education that empowers
          students to achieve their fullest potential.
        </p>
        <div className="admission-tuition">
          {screenType === "mobile" ? (
            <>
              <div>
                <img src={AboutPic} alt="" />
              </div>
              <div>
                <h3>Admission and Tuition</h3>
                <p>
                  We strive to make the admission process as seamless as
                  possible for our students and their families. Our transparent
                  fee structure ensures that every student has access to quality
                  education. Click below to enroll now and take the first step
                  towards a successful academic journey.
                </p>
              </div>
            </>
          ) : (
            <>
              <div>
                <h3>Admission and Tuition</h3>
                <p>
                  We strive to make the admission process as seamless as
                  possible for our students and their families. Our transparent
                  fee structure ensures that every student has access to quality
                  education. Click below to enroll now and take the first step
                  towards a successful academic journey.
                </p>
              </div>
              <div>
                <img src={AboutPic} alt="" />
              </div>
            </>
          )}
        </div>
        <h3>Why Choose Student Plus?</h3>
        <div>
          <p>
            <strong>✨ 20+ Years of Legacy:</strong> With a rich history of
            success, we have been nurturing academic brilliance for over two
            decades.{" "}
          </p>
          <p>
            <strong>📢 Regular Communication:</strong> We ensure continuous
            interaction with both parents and students, fostering a supportive
            learning environment.
          </p>
          <p>
            {" "}
            <strong>👨‍🏫 Personal Mentorship:</strong> Our dedicated mentors
            provide personalized guidance and counseling to address individual
            student needs.
          </p>
          <p>
            <strong>💻 Online & Offline Classes:</strong> Flexible learning
            options to cater to diverse needs, ensuring education is accessible
            anytime, anywhere.
          </p>
          <p>
            {" "}
            <strong>🏫 State-of-the-Art Facilities:</strong> Learn in smart,
            fully air-conditioned classrooms designed to enhance your learning
            experience. Join us at Student Plus, where quality education meets
            quality teachers, and your success is our ultimate goal.
          </p>
        </div>
        <h3>Meet Our Director</h3>
        <div className="director">
          {screenType === "mobile" ? (
            <>
              <img src={Vipul} alt="" />
              <div>
                <h3>A message by our beloved director</h3>
                <div>
                  <p>
                    The Student Plus isn't just about guidance—it's about
                    transformation. Whether you're aiming to redefine
                    leadership, enhance team dynamics, or achieve personal
                    breakthroughs, we're here to empower you every step of the
                    way. <br /> We specialize in turning potential into
                    performance, dreams into reality, and challenges into
                    triumphs.
                  </p>
                  <div>
                    <p>
                      <strong>Name:</strong>Vipul Kumar Srivastava
                    </p>
                    <p>
                      <strong>Mobile:</strong> +919335057978
                    </p>
                    <p>
                      <strong>Email:</strong>{" "}
                      <a href="mailto:info@thestudentplus.com">
                        info@thestudentplus.com
                      </a>
                    </p>
                  </div>
                  <p>
                    <strong>Head Office:</strong> 23/29 Clive Road, Hanuman
                    Mandir Chauraha, Rajapur, Prayagraj.
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <h3>A message by our beloved director</h3>
                <div>
                  <p>
                    The Student Plus isn't just about guidance—it's about
                    transformation. Whether you're aiming to redefine
                    leadership, enhance team dynamics, or achieve personal
                    breakthroughs, we're here to empower you every step of the
                    way. <br /> We specialize in turning potential into
                    performance, dreams into reality, and challenges into
                    triumphs.
                  </p>
                  <div>
                    <p>
                      <strong>Name:</strong>Vipul Kumar Srivastava
                    </p>
                    <p>
                      <strong>Mobile:</strong> +919335057978
                    </p>
                    <p>
                      <strong>Email:</strong>{" "}
                      <a href="mailto:info@thestudentplus.com">
                        info@thestudentplus.com
                      </a>
                    </p>
                  </div>
                  <p>
                    <strong>Head Office:</strong> 23/29 Clive Road, Hanuman
                    Mandir Chauraha, Rajapur, Prayagraj.
                    <br />
                    <br />
                    <strong>Branch Office:</strong> 450 Muir road, near police
                    chauki, Rajapur, Prayagraj
                  </p>
                </div>
              </div>
              <img src={Vipul} alt="" />
            </>
          )}
        </div>

        <h3>Our Social Media Handles</h3>
        <div className="social-media">
          <a
            href="https://wa.me/9335057978"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-whatsapp whatsapp-icon"></i>
            {"    "}+919335057978
          </a>
          <br />
          <a
            href="https://instagram.com/thestudentplus_official?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram instagram-icon"></i>
            {"    "}thestudentplus_official
          </a>
          <br />
          <a
            href="https://www.youtube.com/@StudentPlus_Official"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-youtube youtube-icon"></i>
            {"    "}StudentPlus_Official
          </a>
          <br />
          <a
            href="https://www.facebook.com/studentplusallahabad/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook facebook-icon"></i>
            {"    "}studentplusallahabad
          </a>
        </div>
      </div>
    </AboutDiv>
  );
};

export default About;

import styled from "styled-components";

export const LandingPageContainer = styled.div<{
  data: any;
}>`
  &.tablet {
    & > div {
      & > .welcome-image {
        & > .welcome-image-content {
          & > .title {
            font-size: 44px;
          }
        }
      }
    }
  }
  &.mobile {
    & > div {
      & > .welcome-image {
        height: 600px;
        & > img {
          height: 100%;
        }
        & > .welcome-image-content {
          width: calc(100% - 84px);
          & > .title {
            font-size: 30px;
          }
          & > .description {
            font-size: 14px;
          }
        }
      }
      & > .our-vision {
        grid-template-columns: 1fr;
        .content {
          padding: 16px;
          height: 400px;
        }
        .content-video {
          height: 500px;
          display: block;
        }
      }
      & > .our-programs {
        & > .content {
          grid-template-columns: 1fr;
        }
      }

      & > .address-form {
        display: flex;
        flex-direction: column-reverse;
        & > .address {
          text-align: center;
          margin-top: 20px;
        }
        & > .form-data {
          & > h1 {
            text-align: center;
          }
          & > form {
            & > input {
              margin: 10px 0;
              width: calc(100% - 24px);
            }
            & > div {
              display: block;
              & > input {
                margin: 10px 0;
                width: calc(100% - 24px);
              }
            }
          }
        }
      }
    }
  }
  & > div {
    & > .welcome-image {
      position: relative;
      & > img {
        width: 100%;
        aspect-ratio: 3/2;
      }
      & > .welcome-image-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-shadow: var(--text-shadow);
        width: calc(100% - 200px);
        & > .title {
          font-size: 72px;
          color: #ffde59;
          margin-bottom: 33px;
        }
        & > .description {
          color: white;
          line-height: 1.875em;
          text-align: left;
          font-size: 16px;
        }
      }
    }
    & > .our-programs {
      & > h2 {
        text-align: center;
        font-size: var(--header-font-size);
        color: var(--brand-color);
        width: 100vw;
      }
      & > .content {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 20px;
        padding-top: 0;
        gap: 20px;
        text-align: center;
        align-items: stretch;
        & > div {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          & > button {
            width: 90px;
            border-radius: 20px;
            align-self: center;
            padding: 7px;
            background: wheat;
          }
          & > h4 {
            color: #75ccaa;
          }
        }
      }
    }
    & > .our-vision {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: 580px;
      grid-template-columns: 1fr 1fr;

      .content {
        padding: 24px;
        background: rgb(11, 41, 59);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        & button {
          width: 90px;
          border-radius: 20px;
          align-self: center;
          padding: 7px;
          background: white;
        }
        > div {
          max-width: 100%;

          h1 {
            color: #75ccaa;
            line-height: 1.35em;
            font-size: 2.5rem;
          }

          p {
            color: white;
            line-height: 1.875em;
            font-size: 1rem;
          }
        }
      }

      .content-video {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;

        video {
          max-width: 100%;
          height: auto;
          display: block;
          width: 100%;
        }
      }
    }

    & > .our-results {
      text-align: center;
      font-size: var(--header-font-size);
      color: var(--brand-color);
      width: 100vw;
      & img {
        width: 100%;
        height: auto;
      }
    }
    & > .address-form {
      padding: 24px;
      display: grid;
      grid-template-columns: 50% 50%;
      background: var(--logo-color1);
      & > .address {
        max-width: 350px;
      }
      & > .form-data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        & > form {
          & > div {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-bottom: 10px;
          }
          & input {
            background: transparent;
            padding: 10px;
            border: none;
            border-bottom: 1px solid black;
            width: 100%;
            &[placeholder="Email"] {
              margin: 10px 0;
              width: calc(100% - 24px);
            }
            &:focus {
              border-color: red;
            }
          }
          & > textarea {
            height: 126px;
            width: 100%;
            background: transparent;
            margin-bottom: 24px;
            padding: 10px;
            border: none;
            border-bottom: 1px solid black;
            width: calc(100% - 24px);
          }
          & > button {
            background: black;
            color: white;
            line-height: 1.2;
            width: 100%;
            padding: 8px;
            font-size: 18px;
          }
        }
      }
    }
  }
`;

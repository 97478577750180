import { useState, useEffect } from "react";

/**
 * Custom hook to determine screen size category.
 * @returns {string} The screen size category.
 */
const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState(getScreenSize());

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(getScreenSize());
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenSize;
};

// Function to determine screen size category based on width
const getScreenSize = () => {
  const width = window.innerWidth;

  if (width >= 1200) return "ldesktop";
  if (width >= 992) return "mdesktop";
  if (width >= 768) return "tablet";
  return "mobile";
};

export default useScreenSize;

import styled from "styled-components";
export const RishiKulam = styled.div`
  &.tablet {
    & > h3 {
      height: 175px;
    }
    & h3 {
      font-size: 36px !important;
    }
  }
  &.mobile {
    & > h3 {
      height: 150px;
      text-align: center;
    }
    & h3 {
      font-size: 28px !important;
    }
  }
  & > h3 {
    height: 250px;
    background: var(--brand-color);
    color: var(--logo-color1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--top-header-font-size);
    margin: 0;
  }
  & > div.our-results {
    text-align: center;
    font-size: var(--header-font-size);
    color: var(--brand-color);
    width: 100vw;
    & img {
      width: 100%;
      height: auto;
    }
  }
  & > div.rishikulam-school {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 36px;
    & > h3 {
      font-size: var(--header-font-size);
      color: var(--brand-color);
    }
    & ol {
      padding: 0;
      padding-left: 14px;
    }
    & li {
      font-size: var(--para-font-size3);
      line-height: 1.5;
      margin: 0;
      & > a > .fab {
        margin-right: 4px;
      }
    }
    .fancy-back-button {
      display: inline-flex;
      align-items: center;
      background-color: var(--brand-color);
      color: #fff;
      border: none;
      border-radius: 8px;
      padding: 12px 24px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.2s ease;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      & i {
        margin-right: 8px;
        font-size: 12px;
      }

      &:hover {
        background-color: var(--logo-color2);
        transform: scale(1.05);
      }

      &:active {
        background-color: var(--logo-color1);
        transform: scale(0.95);
      }
    }
  }
`;
